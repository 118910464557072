html {
    background-color: #1a1a1a;
    color: #ffffff;
    overscroll-behavior-y: none;
    /* scroll-behavior: smooth; */
}

body {
    padding: 0px;
    margin: 0px;
    overscroll-behavior-y: none;
}

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
}